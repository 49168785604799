:root {
  --blue: #E08C0D;
  --cyan: #F5B451;
  --veblue: #0ebdbd;
  --vecyan: #6ce3e3;
  --inblue: #4bcb39;
  --incyan: #157307;
  --coblue: #389f53;
  --cocyan: #68CA82;
  --opblue: #008AE0;
  --opblue2: #4fbaff;
  --opcyan: #33b1ff;

}

.brand {
  z-index: 1;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 24px; */
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
}

.logo {
  position: relative;
  line-height: 64px;
  transition: all 0.3s;
  overflow: hidden;
  padding-left: 30px;
}

.logo img {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  transform: translateY(6px);
  @media (max-width: 1023px) {
    transform: translateY(-1px);
  }
}

.ant-layout-sider-collapsed {
  .logo {
    padding-left: 3px;

    img {
      width: 72px;
    }
  }
}

.logo h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.header {
  height: 64px;
  padding: 0;
  position: relative;
}

.header span {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
}

.header .right {
  float: right;
  height: 100%;
  overflow: hidden;
}

.top-menu {
  padding: 0px;
  height: 48px;
  line-height: 48px;
  width: 100%;
  z-index: 19;
}

.top-nav-header {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, .12);
  transition: background .3s, width .2s;
}

.top-nav-header-main {
  display: flex;
  height: 100%;
  padding-left: 16px;
}

.top-nav-header-main-left {
  display: flex;
  min-width: 192px;
}

.top-nav-header-logo {
  position: relative;
  min-width: 165px;
  height: 100%;
  overflow: hidden;
}

.top-nav-header-logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}

.top-nav-header-logo h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  vertical-align: top;
}

.top-nav-header-menu {
  min-width: 0;
}

.menuContainer {
  overflow-x: hidden;
  flex: 1;
  padding: 24px 0;
}


.appHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  @media (max-width: 1023px) {
    padding: 0;
  }
  font-size: 22px;

  .ant-menu {
    background: transparent !important;
  }
}

.ant-layout-header {
  background-color: #1E2131 !important;
  border-bottom: 1px dotted #686161;
}

@media (max-width: 1023px) {
  .ant-layout-header {
    padding: 0px;
    position: absolute;
    top: 0;
    background: none !important;
    right: 0;
  }
  .mobile-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
  }
}

.widget {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  justify-content: space-between;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  > div {
    border: 1px dashed #3b415d;
    background-color: #32384E;
    border-radius: 10px;
    display: block;
    width: 100%;
  }
}

.ant-layout-content {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 64px);
  @media (max-width: 1023px) {
    overflow: hidden;
    .mobileScroll {
      overflow-x: hidden;
      overflow-y: auto;
    }
    .ant-page-header {
      padding: 15px 10px;
    }
  }

}


.recharts-responsive-container, .mapouter iframe {
  height: 300px !important;
  @media (max-width: 1200px) {
    height: 200px !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #2D3244;
  outline: 1px solid #3c4159;
}


.mr-row-verification-inprogress, .mr-row-verified {
  .ant-table-cell {
    color: #000;

    a {
      color: #ad1616;
    }

    .anticon {
      color: #000;
    }
  }

  .ant-table-cell-row-hover {
    color: #a8a8a8;

    .anticon {
      color: #a8a8a8
    }

    a {
      color: #fda342;
    }
  }

  &:hover {
    color: #fff;
  }
}


.ant-btn-dashed .anticon-plus {
  color: #fff;
  width: 25px;
  height: 25px;
  background: #000;
  border-radius: 20px;
  position: relative;
  top: -4px;

  svg {
    font-size: 16px;
    position: relative;
    top: 5px;
  }
}

.ant-checkbox-inner, .ant-radio-inner {
  border-color: #a8a8a8 !important;
}

.ant-radio-wrapper-checked {
  .ant-radio-inner {
    border-color: #00a5ff !important;
  }
}

.ant-btn > .ant-btn-loading-icon svg {
  color: rgb(255, 255, 255);  
  font-size: 20px;
  width: 30px;
}

.ant-input-search-button .anticon-scan {
  color: #000;
  font-size: 16px;
}

.ant-card {
  background-color: #1e2131 !important;
}

.deliChart {
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 330px;
  max-height: 330px;
  @media (max-width: 1023px) {
    min-height: 200px;
    max-height: 200px;
  }

  > div {
    display: grid;
    grid-template-columns: 95px 180px 1fr 1fr 1fr 1fr;
    @media (max-width: 800px) {
      grid-template-columns: 45px 180px 1fr 1fr 1fr 1fr;
    }
    grid-column-gap: 3px;
    align-items: center;
    justify-content: space-between;

    > div {
      position: relative;

      > div {
        height: 8px;
        position: relative;
        overflow: hidden;

        &:before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: -100%;
          will-change: transform;
          background: #363d56;
        }
      }

      @media (min-width: 1024px) {
        &:before {
          content: attr(toolTip);
          position: absolute;
          display: inline-block;
          padding: 0 6px;
          background-color: #000;
          color: #fff;
          top: -26px;
          border-radius: 6px;
          visibility: hidden;
          opacity: 0;
          transition: opacity 1s ease;
          z-index: 1;
          left: 0;
        }
        &:hover:before {
          opacity: 1;
          visibility: visible;
          cursor: pointer;
        }
      }
    }

  }
}

@keyframes progress {
  0% {
    transform: translate(0)
  }
  100% {
    transform: translate(50%);
  }
}


.deliChart {
  > div {
    padding-bottom: 3px;

    > div.open {
      > div {
        &:before {
          background: linear-gradient(90deg, var(--opblue) 0, var(--opblue) 50%, var(--opcyan) 0%, var(--opcyan) 90%);
          animation: 1.2s progress ease infinite;
        }
      }
    }

    > div.verification {
      > div {
        &:before {
          background: linear-gradient(90deg, var(--blue) 0, var(--blue) 50%, var(--cyan) 0%, var(--cyan) 90%);
          animation: 1.5s progress ease infinite;
        }
      }
    }

    > div.verified {
      > div {
        &:before {
          background: linear-gradient(90deg, var(--veblue) 0, var(--veblue) 50%, var(--vecyan) 0%, var(--vecyan) 90%);
          animation: 1.8s progress ease infinite;
        }
      }
    }

    > div.inprogress {
      > div {
        &:before {
          background: linear-gradient(90deg, var(--coblue) 0, var(--coblue) 50%, var(--inblue) 0%, var(--inblue) 90%);
          animation: 2s progress ease infinite;
        }
      }
    }

    > div.complete {
      > div {
        &:before {
          background: linear-gradient(90deg, var(--coblue) 0, var(--coblue) 50%, var(--cocyan) 0%, var(--cocyan) 90%);
          animation: 2.1s progress ease infinite;
        }
      }
    }

    > div {
      &:first-child {
        background: none;
        display: flex;

        img {
          width: 12px;
          margin-right: 4px;
        }

        span {
          display: inline-block;
          color: #fdde94;
          @media (max-width: 800px) {
            width: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: unset;
            height: 21px;

          }
        }

        &:before {
          display: none;
        }
      }

      &:nth-child(2) {
        height: auto;
        min-width: 100px;
        text-align: right;
        padding-right: 5px;

        &:before {
          display: none;
        }
      }
    }
  }

  > div.complete {
    > div {
      > div {
        &:before {
          background: linear-gradient(90deg, var(--coblue) 0, var(--coblue) 50%, var(--cocyan) 0%, var(--cocyan) 90%);
        }
      }
    }
  }
}


.leganProg {
  display: flex;
  align-items: center;

  > div {
    padding-right: 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 15px;

    > span {
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 3px;
    }

    &:first-child {
      > span {
        background-color: var(--opblue);
      }
    }

    &:nth-child(2) {
      > span {
        background-color: var(--cyan);
      }
    }

    &:nth-child(3) {
      > span {
        background-color: var(--veblue);
      }
    }

    &:last-child {
      > span {
        background-color: var(--inblue);
      }
    }
  }
}

/*Defalt*/

.wrapper {
  padding: 15px;
  background-color: #1E2131;
  display: grid;
  grid-template-areas: 'widgets widgets widgets' 'chart chart map' 'agi agi agi' 'ana deli deli';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 155px 1fr 1fr 1fr;
  grid-gap: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 70px);
  .agin {
    padding: 0 10px 10px 10px;
    .ant-spin-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
      @media (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
      }

      .aginChart {
        background: #2e3349;
        border-radius: 20px;
        width: 100%;
        text-align: center;

        h1 {
          position: relative;
          top: 25px;
          color: #bfc1cd;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper {
    grid-template-rows: 332px 1fr 1fr 1fr;
  }
}

@media (max-width: 1023px) {
  .wrapper {
    height: calc(100vh - 160px);
  }
}

@media (max-width: 800px) {
  .wrapper {
    grid-template-rows: 670px 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'widgets widgets widgets' 'chart chart chart'  'ana ana ana' 'agi agi agi' 'deli deli deli' 'map map map';
  }
}

.wrapper > div {
  background: #282D40;
  border-radius: 20px;
  padding-bottom: 15px;
}

.wrapper > div.widgets {
  background: transparent;
}

.widgets {
  grid-area: widgets;
}

.chart {
  grid-area: chart;
}

.map {
  grid-area: map;
  width: 100%
}

.analysis {
  grid-area: ana;
}

.agin {
  grid-area: agi;
}

.delivery {
  grid-area: deli;
}



/*HOme Tv*/
.withoutMap {
  grid-template-areas: 'widgets widgets widgets' 'chart chart chart' 'ana ana ana';
  grid-template-rows: 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-rows: 760px 1fr 1fr;
  }
  @media (max-width: 800px) {
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'widgets widgets widgets' 'chart chart chart'  'ana ana ana';
  }

  > div {
    padding-bottom: 0;
  }

  .widget {
    > div {
      min-height: 182px;

      .title {
        line-height: initial;
      }
    }
  }

  .chart {
    padding: 0 10px 10px 10px;
    .ant-spin-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 10px;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
      }
      @media (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
      }

      .aginChart {
        background: #2e3349;
        border-radius: 20px;
        width: 100%;
        text-align: center;

        h1 {
          position: relative;
          top: 25px;
          color: #bfc1cd;
        }
      }
    }
  }

  .analysis {
    background-color: transparent !important;

    .agin2 {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }

      > div {
        background: #282D40;
        border-radius: 20px;
      }
    }
  }
}

.h2title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    margin-bottom: 5px;
    > div {
      line-height: 22px;
      text-align: left;
      margin-right: 5px;
    }
  }
}
.recharts-sector {
  stroke: #2e3349 !important;
}

text {
  fill: #ddd !important;
}

path {
  opacity: 0.9 !important;
}

.ageing{
  display: flex;
  grid-gap: 15px;
  position: relative;
  > div {
    position: relative;
    color: #c5cffc;
    &:first-child{
     span{ background: rgb(94, 162, 108);}
    }
    &:nth-child(2){
      span{background: rgb(71, 115, 159);}
    }
    &:nth-child(3){
      span{background: rgb(183, 180, 67);}
    }
    &:nth-child(4){
      span{background: rgb(194, 113, 78);}
    }
    &:last-child{
      span{background: rgb(166, 65, 83);}
    }
    span{
      width: 18px;
      height: 10px;
      border-radius: 2px;
      display: inline-block;
      margin-right: 6px;

    }
  }
}

.title-ageing{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active{
  color: #fff;
}