@import '~antd/dist/antd.dark.css';

body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1E2131;
  overflow: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.margin-right {
  margin-right: 16px;
}

.App-link {
  color: #61dafb;
}

.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.order.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 140px;
}

.material-request.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 115px;
}

.search-result-list {
  margin-top: 16px;
  border: 1px dashed #303030;
  border-radius: 6px;
  background-color: #141414;
  min-height: 200px;
  text-align: center;
  /* padding-top: 80px; */
}

.search-result-empty-list {
  margin-top: 16px;
  border: 1px dashed #49547B;
  border-radius: 6px;
  background-color: #32384E;
  min-height: 50px;
  text-align: center;
  padding-top: 10px;
}

.search-form {
  padding: 10px;
  background: #32384E;
  border: 1px solid #49547B;
  border-radius: 2px;
  margin-bottom: 10px;
}

.search-form .ant-form-item {
  display: flex;
  margin-bottom: 10px;
}

.search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#form-create .ant-btn {
  margin-right: 8px;
}

.ant-form-vertical .ant-form-item-label > label {
  font-weight: 600;
}

tr.blue-1 th, tr.blue-1 td {
  background-color: #e6f7ff;
}
tr.blue-2 th, tr.blue-2 td {
  background-color: #bae7ff;
}
tr.blue-4 th, tr.blue-4 td {
  background-color:#69c0ff;
}

.mr-row-verification-inprogress {
  background-color: #ffd666;
}
.mr-row-verified {
  background-color: #bae637;
}
.mr-row-inprogress {
  background-color: #5b8c00;
}
.st-row-return {
  background-color: #ffc069;
}

.request-open {
  background-color: #69b1ff;
}
.request-verification {
  background-color: #bae0ff;
}
.request-prepare {
  background-color: #87e8de;
}
.request-ready {
  background-color: #b7eb8f;
}
.request-processing {
  background-color: #73d13d;
}
.request-complete {
  background-color: #389e0d;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print { 
  .no-printme  { display: none;}
  .printme  { display: block;}
}

.ant-menu-dark.ant-menu-inline{
  padding-left: 10px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #404C6F;
  transform: translateY(-20px);
  border-radius: 0 0 0 15px;
  padding-top: 20px;
}

.ant-menu-submenu-open .ant-menu-submenu-title,  .ant-menu-dark .ant-menu-submenu-title:hover{
  background: #1E2131;
  border-radius: 20px 0 0 20px;
  position: relative;
  z-index: 6;
}

.ant-menu-item-selected{
  border-radius: 20px 0 0 20px;
}

.ant-layout-sider, .ant-menu.ant-menu-dark{
  background: #2D3244;
}

.ant-layout-content, .ant-table{
  background: #1E2131;
}


 .ant-table  {
   background: #32384E;
   border: 1px solid #49547B;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
  background: #141d31;
}


.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{
  border-right: none;
  border-left: none;
  border-top: 1px solid #49547B;
}
a{
  color: #fda342;
}
.ant-input{
  border: 1px dotted #8f95ab;
}

.ant-input::placeholder {
  color: #8f95ab;
}

.ant-btn-default{
  background: #e9edf5;
  color: #141d31;
}


.ant-menu-dark.ant-menu-inline, .ant-menu-vertical{
  padding: 15px  0;
  padding-left: 12px;
}
.ant-btn-primary{
background: #48cae4;
  border: 1px solid #23d7fd;
  color: #141d31;
}




.ant-layout-footer {
    padding: 0 15px;
    color: #737a96;
    background: #151825;
    height: 30px;
  font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


h2{
  font-weight: 100;
  text-align: center;
  padding: 8px 15px;
  margin-bottom: 0;
}

.mapouter{
  border-radius: 10px;
  padding: 0 10px;
}
.mapouter iframe {
  /*height: 340px;*/
  width: 100%;
}
.map .search{
  padding: 10px;
}

.map .search input{
  border: none;
  height: 40px;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
}
.map .search input:focus{
  border: none;
  box-shadow: none;
}
.map .search .ant-btn-default{
  background: #282e41;
  border: none;
  font-size: 39px;
  height: 40px;
  width: 50px;
}

.map .search .anticon-search{
  font-size: 30px;
}

.map .search .ant-space{
  width: 100%;
}

.ant-row .ant-form-item-row{
  width: 100%;
}
.ant-picker-range{
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper, .ant-picker, .anticon{
  border-color: #a8a8a8;
  color: #a8a8a8;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector
,.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-picker:hover, .ant-picker-focused{
  border-color: #ed8f03
}

.ant-badge{
  color: #000;
  font-size: 13px;
}

.mr-row-verification-inprogress{
  color: #000;
}
.ant-tag-has-color{
  color: #000;
}
