.widget {
  > div {
    min-height: 150px;

    > div {
      font-size: 18px;
      @media (max-width: 400px) {
        font-size: 15px;
      }

      > div {
        &:first-child {
          display: flex;
          justify-content: space-between;
          background: #373D56;
          padding: 5px 20px;

          img {
            max-width: 28px;
            height: 28px;
            @media (max-width: 1200px) {
              max-width: 27px;
            }
          }
        }
      }
    }


  }

  .numberValue {
    display: grid;
    flex-wrap: wrap;
    padding: 0 22px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    //@media (max-width: 400px) {
    //  font-size: 30px;
    //}

    > div {
      min-width: 100px;
      padding: 6px 0;
      .suffix {
        font-size: 18px;
        color: #ececec;
      }
      .title{
       line-height: 17px;
        font-size: 13px;
      }
    }
  }
}

.boxs {
  color: #BCFFCF;
}
.boxs2 {
  color: #bfb5ff;
}


.stock {
  color: #FFFBA5;
}
.stock2 {
  color: #e9ca98;
}

.gross {
  color: #FFBAC6;
}

.total_cones {
  color: #B4DBFF;
}
.total_cones2 {
  color: #d6a1dc;
}

.boxs  + .numberValue{
  .title{
    color: #BCFFCF;
  }
}

.boxs2  + .numberValue{
  .title{
    color: #bfb5ff;
  }
}



.stock  + .numberValue{
  .title{
    color: #FFFBA5;
  }
}
.stock2  + .numberValue{
  .title{
    color: #e9ca98;
  }
}


.gross  + .numberValue{
  .title{
    color: #FFBAC6;
  }
}

.total_cones  + .numberValue{
  .title{
    color: #B4DBFF;
  }
}

.total_cones2  + .numberValue{
  .title{
    color: #d6a1dc;
  }
}