.LoginForm {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  height: 320px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}

.LoginForm button {
  width: 100%;
}

.LoginForm p {
  color: rgb(204, 204, 204);
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.LoginForm-logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.LoginForm-logo img {
  width: 180px;
  margin-bottom: 31px;
}

.LoginForm-logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 0 16px;
  margin: 48px 0 24px 0;
}
