.LeftMenu {
  background: #2D3244;
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  .ant-menu-submenu {
    padding-top: 10px;
  }
}

.ant-menu-submenu {
  > .ant-menu-submenu-title {
    > .ant-menu-title-content {
      margin-left: 32px;
    }
  }

}

.menuIcon {
  position: absolute;
  transform: translateY(25px);
  z-index: 8;
  margin-left: 22px;
  width: 20px;
  pointer-events: none;
}

.dasboard {
  width: 20px;
  margin-right: 10px;
}

.ant-layout-sider-collapsed {
  .ant-menu-inline-collapsed-noicon {
    display: none;
  }

  span {
    span {
      display: none;
    }
  }

  .dasboardw {
    span {
      img {
        position: relative;
        left: -4px;
      }
    }
  }
}
.mobileMenu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  text-align: center;
  height: 50px;
  z-index: 2;
  border-radius: 3px 3px 0 0;
  background-color: #f6f6f6;
  //border: 1px solid #ddd;

  > div {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      filter: brightness(0.5);
      width: 28px;
    }
  }
}

/*Mobile*/
.mobileSubMenu {
  position: absolute;
  top: 64px;
  background: #242734;
  left: 0;
  right: 0;
  bottom: 0;

  > div {
    height: 100%;
    display: grid;
    grid-gap: 5px;
    justify-content: center;
    align-items: center;
    grid-template-columns:  1fr 1fr;
    grid-template-rows:  1fr 1fr 1fr 1fr;
    padding: 5px 35px;
    height: 50px;

    > div {
      background: #39405C;
      border-radius: 8px;
      padding: 15px;
      height: 100%;
      min-height: 103px;

      a {
        display: grid;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        grid-template-rows: 1fr 1fr;
        align-items: flex-end;

        > div {
          width: 35px;
          height: 35px;
          border-radius: 50px;
          text-align: center;

          img {
            transform: translateY(4px);
          }
        }

        > span {
          display: block;
          padding-top: 5px;
          color: #fff;
          font-weight: bold;
          font-size: 15px;
        }
      }
    }
  }
}
.footerIcon{
  button {
    border: none;
    background: transparent;
    outline: none;
  }

  button.active {
    img {
      filter: none;
    }
  }
}
.icon_1 {
  background: #C93709;
}

.icon_2 {
  background: #00BD9B;
}

.icon_3 {
  background: #735530;
}

.icon_4 {
  background: #B6BA00;
}

.icon_5 {
  background: #AA00AD;
}

.icon_6 {
  background: #55C000;
}

.icon_7 {
  background: #5B65F4;
}

.icon_8 {
  background: #EC2CB6;
}