.contentInner {
  /*padding-top: 15px;*/
  height: calc(100vh - 270px);
  position: relative;
}

/*@media (max-width: 767px) {*/
/*  .contentInner {*/
/*    padding: 12px;*/
/*    min-height: calc(100vh - 160px);*/
/*  }*/
/*}*/

@media print {
  @page {
    margin-top: 1cm;
    margin-bottom: 1cm;
    margin-left: 1cm;
    margin-right: 1cm;
  }
}
