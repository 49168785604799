

.numberInfoTitle {
  margin-bottom: 16px;
  --color: @text-color;
  --font-size: @font-size-lg;
  transition: all 0.3s;
}
.numberInfoSubTitle {
  height: 22px;
  overflow: hidden;
  --color: @text-color-secondary;
  --font-size: @font-size-base;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.numberInfoValue {
  margin-top: 4px;
  overflow: hidden;
  font-size: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.numberInfoValue.suffix {
  margin-left: 4px;
  --color: @text-color;
  font-size: 16px;
  font-style: normal;
}
.subTotal {
  margin-right: 0;
  --color: @text-color-secondary;
  --font-size: @font-size-lg;
  vertical-align: top;
}
.numberFooter {
  margin-top: 8px;
  padding-top: 9px;
  border-top: 1px solid #f0f0f0;
}
.numberFooter.footerField {
  position: relative;
}
